/*
 * @Author: 宋绍华
 * @Date: 2022-04-15 18:06:12
 * @LastEditTime: 2022-04-18 12:33:42
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \online-editor-pc\src\components\plugins\File.ts
 */
import { FileComponent } from '@aomao/plugin-file'
import { sanitizeUrl } from '@aomao/engine'

export default class File extends FileComponent {
  static get pluginName() {
    return 'file'
  }
  static get cardName() {
    return 'file';
  }
  triggerOpen = (type: string, url: string, filename: string = '') => {
    if (!url) return
    if (type === 'preview') {
      const link = document.createElement('a')
      link.setAttribute("download", filename);
      link.setAttribute('target', '_blank')
      link.setAttribute('href', url);
      link.setAttribute('style', 'position: absolute;top: -99999px;left: -99999px;');
      document.body.appendChild(link);
      link.click()
      setTimeout(() => {
        document.body.removeChild(link);
      }, 0);
    } else {
      const _url = url.replace(/(http:)|(https:)/g, "")
      this.getBlob(_url, (blob) => {
        this.saveAs(blob, filename)
      })
    }
  }
  // 请求数据
  getBlob(url: string, cb: (resp: XMLHttpRequest["response"]) => void) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status === 200) {
        cb(xhr.response);
      }
    };
    xhr.send();
  }
  // 下载文件
  saveAs(blob: Blob, filename: string) {
    // @ts-ignore
    if (window.navigator?.msSaveOrOpenBlob) {
      // @ts-ignore
      navigator?.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      const body = document.querySelector('body');
      if (!body) return

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // fix Firefox
      link.style.display = 'none';
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    }
  }

  previewFile = () => {
    const value = this.getValue();
    if (!value?.preview) return;
    const { preview } = value;
    this.triggerOpen('preview', sanitizeUrl(this.onBeforeRender('preview', preview)));
  };

  downloadFile = () => {
    const value = this.getValue();

    if (!value?.download) return;
    const { download, name } = value;

    this.triggerOpen('download', sanitizeUrl(this.onBeforeRender('download', download)), name);
  };
}
