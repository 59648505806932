
import { defineComponent, PropType } from "vue"
interface Mentions {
  [key: string]: string
}
export default defineComponent({
    name:"am-mention",
    props:{
      data: {
        type: Object as PropType<Mentions>,
        default: () => {}
      }
    },
})
