import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mention-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, "姓名: " + _toDisplayString(_ctx.data.name), 1),
    _createElementVNode("p", null, "职位: " + _toDisplayString(_ctx.data.job_name), 1),
    _createElementVNode("p", null, "部门: " + _toDisplayString(_ctx.data.job_path), 1)
  ]))
}