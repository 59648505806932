/*
 * @Author: 宋绍华
 * @Date: 2022-04-26 15:56:10
 * @LastEditTime: 2022-04-26 15:58:39
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \online-editor-pc\src\components\api.ts
 */
import { baseUrl } from "@/utils"

const api = {
  noticeSomeoneApi: `${baseUrl}/api/tomato/wiki/title`, // 通知某人
}
export default api
