/*
 * @Author: 宋绍华
 * @Date: 2021-12-07 14:09:50
 * @LastEditTime: 2022-04-26 16:03:10
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \online-editor-pc\src\components\request.ts
 */
import { baseUrl, getSessionTomatoParams } from "@/utils"

import axios from "axios"
import api from "./api"
// 下载文件
export const downloadFile = async (docId: string) => {
  const { data } = await axios.get(`/editor-api/download?docId=${docId}`)
  const { code, data: resp } = data
  if (code === 200) return resp
}

// 提及信息
export const mentionSomeOne = async (key: string) => {
  const { contentId, token } = getSessionTomatoParams()
  axios.post(
    api.noticeSomeoneApi,
    { content_id: contentId, mentioned_staff: [key] },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

// 更新改变html的时间
export const updateHtmlTime = (content_id: string, token: string) => {
  const url = `${baseUrl}/api/tomato/wiki/title`
  axios.post(
    url,
    { content_id },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}
