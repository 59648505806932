/*
 * @Author: 宋绍华
 * @Date: 2021-12-07 14:09:50
 * @LastEditTime: 2022-05-07 16:24:02
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \online-editor-pc\src\utils\index.ts
 */
// 下面两个package要单独安装
import { message } from 'ant-design-vue'
import { MessageType } from 'ant-design-vue/lib/message'
import FileSaver from 'file-saver'
import htmlDocx from 'html-docx-js'
import mammoth from 'mammoth'
import axios from 'axios'
import { uploadUrl } from '@/components/config'

let timer: NodeJS.Timeout | null = null
let loading: MessageType | null

// tomato接口域名
export const baseUrl = location.host.includes('online-editor.petrvet.com') ? '//tomato.petrvet.com' : '//test.tomato.rvet.cn'
// 编辑器接口前缀
export const baseEditorUrl = location.host.includes('test.online-editor.rvet.cn') ? '//test.online-editor.rvet.cn' : '//online-editor.petrvet.com'

// 拼接单个html
export const createSingleHtml = () => {
  const pdfContainer = document.getElementById('pdfContainer')
  const container2 = document.getElementById('container2')
  if (!pdfContainer || !container2) return
  const elm = container2.cloneNode(true)
  pdfContainer.appendChild(elm)
}

/**
 * 导出word
 * @param {String} content 需要导出的html
 * @param {String} 导出的名字
 */
export const getWord = (content: string, name: string) => {
  if (!!loading) return
  loading = message.loading({ content: '正在生成Word中...' }, 0)

  if (timer) clearTimeout(timer)

  timer = setTimeout(() => {
    loading!()
    let converted = htmlDocx.asBlob(content)
    // @ts-ignore
    FileSaver.saveAs(converted, name)
    loading = null
  }, 1000)
}

// 获取host
export const getHost = (() => {
  const isProd = process.env.NODE_ENV === 'production'
  return isProd ? (window.location.host.indexOf('test') > -1 ? 'test.online-editor.rvet.cn' : 'online-editor.petrvet.com') : 'localhost:9101'
})()

// 获取本地的tomato 参数
export const getSessionTomatoParams = () => {
  return typeof sessionStorage.getItem('tomatoParams') === 'string' ? JSON.parse(sessionStorage.getItem('tomatoParams')!) : {}
}

// 转换word成html，目前只支持docx格式
export const convertWordToHtml = async (arrayBuffer: ArrayBuffer, options?: Object) => {
  try {
    const result = await mammoth.convertToHtml({ arrayBuffer }, options)
    // return result.value.replace(/color="/g, 'style="color:#')
    return result.value
  } catch (e) {
    console.log(e)
  }
}

// 获取文档详情
export const getDocDetailById = async (id: string) => {
  try {
    const { data } = await axios.get('/editor-api/detail/' + id)

    if (data.code === 200) return data.data
  } catch (e) {
    console.log(e)
  }
}

// mammoth options
export const mammothOptions = {
  convertImage: mammoth.images.imgElement((image) => {
    return image.read('base64').then(async (imageBuffer) => {
      const result = await uploadBase64Image(imageBuffer, image.contentType)

      return {
        src: result.data.thumb_url // 获取图片线上的URL地址
      }
    })
  })
}

// 上传base64图片
const uploadBase64Image = async (base64Image: string, mime: string) => {
  const formData = new FormData()
  formData.append('file', base64ToBlob(base64Image, mime))
  return await axios({
    method: 'post',
    url: uploadUrl, // 本地图片上传的API地址
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

const base64ToBlob = (base64: string, mime: string) => {
  mime = mime || ''
  const sliceSize = 1024
  const byteChars = window.atob(base64)
  const byteArrays = []
  for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
    const slice = byteChars.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: mime })
}

// 设置浏览器的放大缩小
export const setChromeZoom = (zoom: number = 1) => {
  const hasZoom = Object.prototype.hasOwnProperty.call(document.body.style, 'zoom')
  // @ts-ignore
  hasZoom && (document.body.style.zoom = zoom)
}

// 生成pdf文件
export const generatePdf = async (html: string, name: string = '', tree?: string) => {
  // 把.都转成-
  name = name.replaceAll(/\./g, '-')
  const url = '/editor-api/generatePdf'
  const { data } = await axios({
    url,
    method: 'post',
    data: { html, name, tree },
    responseType: 'blob',
    timeout: 60000
  })
  const blob = new Blob([data], { type: 'application/pdf' })
  const link = document.createElement('a')
  const src = window.URL.createObjectURL(blob)
  link.download = name
  link.href = src
  link.click()
  link.remove()
  window.URL.revokeObjectURL(src)
  return Promise.resolve(true)
}

interface AwaitFuncIter<U> {
  asyncFunc: CallableFunction
  args?: U
  needLoading?: boolean
  needCode?: boolean
}
/**
 * 为了不写那么多的try catch ，特意封装一个简易的try catch 函数
 * @param asyncFunc 回调函数
 * @param args 参数
 * @param needLoading 是否需要loading，默认true
 * @param needCode 是否需要返回code， 默认false
 * @returns Array
 *
 * example 1:
 * function checkNotices = (id: number) => {
 *  检测提醒，接口没啥返回值，因此根据接口code 是否为200即可
 *  awaitFunc<number, any>
 *  1. number 参数类型，
 *  2. any 为返回数据类型，这里没有返回值，因此设置any即可
 *  { asyncFunc: withDrawApprovalApi, args: id, needCode: true }
 *  1. asyncFunc 异步请求函数
 *  2. args： 接口参数值
 *  3. needCode 是否需要返回code
 *  4. needLoading 是否需要接口loading
 *
 *   const [err, _]: [number | unknown, any] = await awaitFunc<number, any>({ asyncFunc: withDrawApprovalApi, args: id, needCode: true, needLoading: false });
 *   if (typeof err === "number") return err === 200;
 *   return false;
 * }
 *
 * example 2:
 * const useGetDemandList = async (product_id: string): Promise<DemandApprovalItem[] | null> => {
 *  获取需求list
 *  string: 参数类型 , DemandApprovalItem[] 接口返回类型，其余跟example 1 类似
 *   const [err, data]: [unknown, DemandApprovalItem[] | null] = await awaitFunc<string, DemandApprovalItem[]>({ asyncFunc: getDemandApprovalList, args: product_id, needLoading: false });
 *   if (err || !data) return null;
 *   return data;
 * };
 */

export async function awaitFunc<U, T>(params: AwaitFuncIter<U>): Promise<[number | unknown, T | null]> {
  // 参数
  const { asyncFunc, args, needCode = false } = params
  try {
    const { code, data }: { code: number; data: T } = await asyncFunc(args)
    // 非200统一抛出异常
    if (code !== 200) return [needCode ? code : null, null]
    return [needCode ? code : null, data]
  } catch (e: unknown) {
    return [e, null]
  }
}
