
import { defineComponent } from "vue";
import { Select } from 'ant-design-vue'
import EngineDemo from "@/components/index.vue";

export default defineComponent({
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    EngineDemo,
  },
});
