
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { Avatar, message, Modal, Space, Upload } from 'ant-design-vue'
import Engine, { $, EngineInterface, EngineOptions, isMobile } from '@aomao/engine'
import AmToolbar from '@aomao/toolbar-vue'
import AmLoading from './loading.vue'
import { cards, plugins, pluginConfig } from './config'
import OTClient from './ot-client'
import { Outline, OutlineData } from '@aomao/plugin-heading'
import { getWord, getHost, convertWordToHtml, getDocDetailById, setChromeZoom, generatePdf } from '../utils/index'
import { TomatoParams } from '@/utils/types'
import { reactive } from 'vue'
import { updateHtmlTime } from './request'
const outline = new Outline()

interface Members {
  id: number
  name: string
  uuid: string
  index: number
  color: string
  userId: string
}
export default defineComponent({
  name: 'engine-demo',
  components: {
    Avatar,
    Space,
    AmLoading,
    AmToolbar,
    Upload
  },
  data() {
    // toolbar 配置项
    return {
      items: isMobile
        ? [
            ['undo', 'redo'],
            {
              icon: 'text',
              items: ['bold', 'italic', 'strikethrough', 'underline', 'moremark']
            },
            [
              {
                type: 'button',
                name: 'image-uploader',
                icon: 'image'
              },
              'link',
              'tasklist',
              'heading'
            ],
            {
              icon: 'more',
              items: [
                {
                  type: 'button',
                  name: 'video-uploader',
                  icon: 'video'
                },
                {
                  type: 'button',
                  name: 'file-uploader',
                  icon: 'attachment'
                },
                {
                  type: 'button',
                  name: 'table',
                  icon: 'table'
                },
                {
                  type: 'button',
                  name: 'math',
                  icon: 'math'
                },
                {
                  type: 'button',
                  name: 'codeblock',
                  icon: 'codeblock'
                },
                {
                  type: 'button',
                  name: 'orderedlist',
                  icon: 'orderedlist'
                },
                {
                  type: 'button',
                  name: 'unorderedlist',
                  icon: 'unorderedlist'
                },
                {
                  type: 'button',
                  name: 'hr',
                  icon: 'hr'
                }
              ]
            }
          ]
        : [
            ['collapse'],
            ['undo', 'redo', 'paintformat', 'removeformat'],
            ['heading', 'fontfamily', 'fontsize'],
            ['bold', 'italic', 'strikethrough', 'underline', 'moremark'],
            ['fontcolor', 'backcolor'],
            ['alignment'],
            ['unorderedlist', 'orderedlist', 'tasklist', 'indent', 'line-height'],
            ['link', 'quote', 'hr']
          ]
    }
  },
  setup() {
    // 编辑器容器
    const container = ref<HTMLElement | null>(null)
    // 备用编辑器
    const container2 = ref<HTMLElement | null>(null)
    let engineInstance: EngineInterface

    let engineCopyInstance: EngineInterface
    // 编辑器引擎
    const engine = ref<EngineInterface | null>(null)
    let outlineData = ref<Array<OutlineData>>([])
    // 当前所有协作用户
    const members = ref([])
    // 默认设置为当前在加载中
    const loading = ref(true)
    let isSubmit = false
    let timer: NodeJS.Timeout | null = null
    let html = ref('')
    // 是否在生成pdf
    let isGeneratePdf = false
    let OT: OTClient
    let creating = false
    // 是否全屏
    let isFullScreen = ref<boolean>(false)
    // 当前页面一些reactive
    const curPageItems = reactive<{ tree: Record<string, any> }>({
      tree: {}
    })

    // tomato传过来的数据
    const tomatoParams = ref<TomatoParams>({
      contentId: 'sfUAchODo6rRStSy6YX1',
      token: '',
      permission: 0,
      title: '',
      name: '',
      userId: ''
    })

    // const download = async () => {
    //   const content = await downloadFile(tomatoParams.value.contentId)
    //   if (content) {
    //     exportWord(content)
    //   }
    // }

    // 获取h1~h6标签
    const getTocData = (_engineInstance: EngineInterface<EngineOptions>) => {
      // 从编辑区域提取符合结构要求的标题 Dom 节点
      let nodes: Array<Element> = []
      const { card } = _engineInstance
      _engineInstance.container.find('h1,h2,h3,h4,h5,h6').each((child: any) => {
        const node = $(child)
        // Card 里的标题，不纳入大纲
        if (card.closest(node)) {
          return
        }
        // 非一级深度标题，不纳入大纲
        if (!node.parent()?.isRoot()) {
          return
        }
        nodes.push(node.get<Element>()!)
      })
      return outline.normalize(nodes)
    }

    // 导出word
    const exportWord = (content?: string) => {
      return getWord(content || html.value, tomatoParams.value.title + '.docx')
    }

    // 获取socket 协议
    const wProto = () => {
      return window.location.protocol.includes('https') ? 'wss' : 'ws'
    }

    // 获取历史记录
    // const getHistory = () => {
    //   axios.get('/api/getHistroy?id=OV9iE0fH04iUBAwH0gLU').then((res) => {
    //     const { code, data } = res.data
    //     if (code === 200) {
    //       engineInstance.setJsonValue(data)
    //     }
    //   })
    // }

    // 初始化container
    const initContainer = async () => {
      // 容器加载后实例化编辑器引擎
      if (container.value && tomatoParams.value.contentId) {
        //实例化引擎
        engineInstance = new Engine(container.value, {
          // 启用的插件
          plugins,
          // 启用的卡片
          cards,
          // 所有的卡片配置
          config: pluginConfig,
          placeholder: '请输入正文'
        })
        // 设置显示成功消息UI，默认使用 console.log
        engineInstance.messageSuccess = (msg: string) => {
          message.success(msg)
        }
        // 设置显示错误消息UI，默认使用 console.error
        engineInstance.messageError = (error: string) => {
          message.error(error)
        }
        // tomato 后台permission 状态为1时 为只读
        engineInstance.readonly = tomatoParams.value.permission === 1
        // 设置显示确认消息UI，默认无
        engineInstance.messageConfirm = (msg: string) => {
          return new Promise<boolean>((resolve, reject) => {
            Modal.confirm({
              content: msg,
              onOk: () => resolve(true),
              onCancel: () => reject()
            })
          })
        }
        //卡片最大化时设置编辑页面样式
        engineInstance.on('card:maximize', () => {
          $('.editor-toolbar').css('z-index', '9999').css('top', '55px')
        })
        engineInstance.on('card:minimize', () => {
          $('.editor-toolbar').css('z-index', '').css('top', '')
        })

        // 默认编辑器值，为了演示，这里初始化值写死，正式环境可以请求api加载
        // 使用协同编辑，需要安装 mongodb 数据库，并且配置 ot-server/client 中的数据库连接，最后 yarn start 启动 ot-server 服务
        await createOt()

        // 监听编辑器值改变事件
        engineInstance.on('change', (value) => {
          if (engineInstance.readonly) return

          html.value = value
          if (!isSubmit) {
            // 第一次不要调用更新时间的接口
            isSubmit = true
            return
          }
          if (timer) clearTimeout(timer)
          timer = setTimeout(() => {
            updateHtmlTime(tomatoParams.value.contentId, tomatoParams.value.token)
          }, 2000)
        })

        engine.value = engineInstance

        nextTick(() => {
          const onChange = () => {
            //获取大纲数据
            const data = getTocData(engineInstance)
            outlineData.value = data
          }
          //绑定编辑器值改变事件
          engineInstance.on('change', onChange)
          setTimeout(() => {
            onChange()
          }, 50)
          getTocData(engineInstance)
        })
      }
    }

    // 退出OT
    const exitOt = () => {
      OT.exit()
      OT.unbindEvents()
    }

    // 创建编辑客户端
    const createOt = () => {
      return new Promise((resolve) => {
        creating = true
        loading.value = true
        // 销毁当前实例，然后重新创建一个
        if (OT) exitOt()
        // 实例化协作编辑客户端
        OT = new OTClient(engineInstance)
        // 获取当前用户，正式环境可以传Token到 ot-server 中验证
        // const memberData = localStorage.getItem("member");
        // const currentMember = !!memberData ? JSON.parse(memberData) : null;
        // 连接协同服务端，如果服务端没有对应docId的文档，将使用 value 初始化
        const { userId, name, contentId } = tomatoParams.value
        OT.connect(`${wProto()}://${getHost}/ws/?uid=${userId}&name=${name}&userId=${userId}`, contentId)
        OT.on('ready', () => {
          creating = false
          // 为了演示，保存当前会员信息
          loading.value = false
          engineInstance.focus()
          resolve(true)
        })
        //用户加入或退出改变
        OT.on('membersChange', (currentMembers) => {
          members.value = currentMembers
          postMsgToTomato()
        })
      })
    }

    // 发送消息给tomato
    const postMsgToTomato = () => {
      window?.top?.postMessage(
        {
          name: 'updateMembers',
          onlineMembers: JSON.parse(JSON.stringify(onlineMembers.value))
        },
        '*'
      )
    }

    // 整合在线人数
    const onlineMembers = computed(() => {
      return members.value.map((i: Members) => {
        if (typeof i.name === 'string') {
          /**
           * 名字只展示两个，
           * 比如宋绍华，就展示 绍华
           * 程路 就展示 程路
           */
          if (i.name.length === 2) return i
          else if (i.name.length > 2) {
            i.name = i.name.slice(i.name.length - 2)
            return i
          }
          return i
        }
        return i
      })
    })
    // 告诉tomato已经收到消息了，不要在发送消息过来了
    const connectWithTomato = () => {
      window?.top?.postMessage(
        {
          name: 'getTomatoSuccess'
        },
        '*'
      )
    }
    onMounted(() => {
      // 监听tomato传过来的数据
      window.addEventListener('message', async ({ data }) => {
        // 如果是name是tomato并且编辑器引擎事例不存在
        if (data?.name === 'tomato' && !engineInstance) {
          tomatoParams.value = data.data
          if (window.sessionStorage) {
            sessionStorage.setItem('tomatoParams', JSON.stringify(tomatoParams.value))
          }
          // 告诉tomato已经收到消息了，不要在发送消息过来了
          connectWithTomato()
          // 如果tomato没有传content_id过来，直接不去渲染
          if (!data.data?.contentId) return message.error('id不存在')
          // 如果渲染一次，就不用在渲染了
          if (!engineInstance) {
            initContainer()
          }
          // 设置编辑器的状态
          if (engineInstance) {
            ;(engineInstance as Engine).readonly = tomatoParams.value.permission === 1
          }
        } else if (data?.name === 'download') {
          // 导出
          if (data.data?.type === 'word') {
            exportWord()
          } else if (data.data?.type === 'pdf') {
            exportAllPdf(1, data?.data?.title)
          }
        } else if (data?.name === 'fullScreen') {
          // 是否为全屏
          const { fullScreen } = data.data
          isFullScreen.value = fullScreen
          // 如果是全屏状态，为只读状态，其他非全屏状态还是按照它原有的权限走
          const readonly = fullScreen ? true : tomatoParams.value.permission === 1
          engineInstance.readonly = readonly
          // 浏览器缩放比例
          setChromeZoom(fullScreen ? 1.7 : 1)
        } else if (data?.name === 'tree') {
          // 文档树
          if (data.data) {
            const { tree, title } = data.data
            curPageItems.tree = typeof tree === 'string' ? JSON.parse(tree)[0] : tree[0]
            exportAllPdf(2, title)
          }
        } else if (data?.name === 'changeDoc') {
          // 如果存在file，说明是导入word，其他就是一般的创建文档
          // 更换当前文本
          const { contentId, permission, token, file } = data.data
          if (!contentId || contentId === tomatoParams.value.contentId) return
          loading.value = true
          // 先设置只读
          engineInstance.readonly = true
          tomatoParams.value.contentId = contentId
          typeof permission !== 'undefined' && (tomatoParams.value.permission = permission)
          token && (tomatoParams.value.token = token)
          // 创建默认子文档时，需要清理默认的值
          engineInstance.setHtml('')
          // 如果在创建中，就先退出
          if (creating) exitOt()
          // 重新生成一个新的ot
          await createOt()
          engineInstance.readonly = tomatoParams.value.permission === 1
          if (file) {
            // 导入word
            beforeUpload(file)
          }
        }
      })

      if (process.env.NODE_ENV !== 'production') {
        initContainer()
      }
    })

    onUnmounted(() => {
      if (engine.value) engine.value.destroy()
    })

    // 获取缩放
    const getMaxScale = () => {
      const winWidth = document.body.clientWidth
      const containerWidth = document.getElementById('editor-container')!.getBoundingClientRect().width

      return (winWidth / containerWidth).toFixed(1)
    }

    const maxScaleStyle = computed(() => {
      if (isFullScreen.value) {
        return `transform: scale(${getMaxScale()});`
      }
      return ''
    })

    // 上传word
    const beforeUpload = (file: File) => {
      if (!file.name.includes('.docx')) {
        return message.warning('目前导入格式只支持.docx格式')
      }
      const reader = new FileReader()

      reader.onload = async function () {
        const arrayBuffer = reader.result as ArrayBuffer
        const value = await convertWordToHtml(arrayBuffer)
        // 如果value不存在，设置空字符串
        engineInstance.setHtml(value || '')
      }

      reader.readAsArrayBuffer(file)
    }

    // 初始化备份的container
    const initCopyContainer = () => {
      engineCopyInstance = new Engine(container2.value!, {
        // 启用的插件
        plugins,
        // 启用的卡片
        cards,
        // 所有的卡片配置
        config: pluginConfig
      })
    }

    // 获取content_id
    const getContentId = (data: Record<string, any>) => {
      if (Array.isArray(data) && data.length) {
        const arr: string[] = []
        data.forEach((item) => {
          arr.push(item.content_id)
          if (Array.isArray(item.children) && item.children.length) {
            const a1: Record<string, any> = getContentId(item.children)
            if (Array.isArray(a1)) {
              arr.push(...a1)
            }
          }
        })
        return arr
      }
      return []
    }

    // 获取所有的文档信息
    const getAllDocDetail = async () => {
      const ids: string[] = []
      if (curPageItems.tree.content_id) {
        ids.push(curPageItems.tree.content_id)
      }
      const ls = getContentId(curPageItems.tree.children)
      ids.push(...ls)

      if (Array.isArray(ids) && ids.length) {
        const arr: any[] = []
        ids.forEach((item) => arr.push(getDocDetailById(item)))
        const values = await Promise.all(arr)
        if (values.length !== ids.length) {
          message.error('导出失败，请重试')
          return []
        }
        return values
      }
    }

    // 导出全部的pdf文件
    const exportAllPdf = async (type: number, title: string) => {
      if (isGeneratePdf) return
      message.loading({ content: '正在生成PDF中...', duration: 0 })
      initCopyContainer()
      let data
      if (type === 1) {
        data = await getDocDetailById(tomatoParams.value.contentId)
        if (data) data = [data]
      } else {
        // 获取所有的文档
        data = await getAllDocDetail()
      }
      isGeneratePdf = true
      if (data) {
        data.forEach((item: any) => {
          const h = engineCopyInstance.getHtml()
          engineCopyInstance.setJsonValue(item)
          engineCopyInstance.setHtml(h + engineCopyInstance.getHtml())
        })
        try {
          await generatePdf(engineCopyInstance.getHtml(), title, type === 1 ? '' : JSON.stringify(curPageItems.tree))
        } catch (e) {
          console.log(e)
        }
        isGeneratePdf = false
        message.destroy()
      }
      nextTick(() => {
        // 清理两个文档的html
        engineCopyInstance.destroy()
      })
    }

    return {
      exportAllPdf,
      beforeUpload,
      maxScaleStyle,
      isFullScreen,
      loading,
      isMobile,
      container,
      container2,
      engine,
      outlineData
    }
  }
})
