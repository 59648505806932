
import { defineComponent } from "vue"
import ASpin from 'ant-design-vue/es/spin'
import 'ant-design-vue/es/spin/style';

export default defineComponent({
  name: "am-loading",
  components: {
    ASpin
  },
  props: {
    text: String,
    loading: Boolean
  },
})
